@font-face {
  font-family: 'Sentient';
  src: url('/fonts/Sentient-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

img {
  pointer-events: none;
}

#kp-stripe-button-container {
  display: none;
}
